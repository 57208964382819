.button {
    --gap: 6px;
    --height: 46px;
    --padding: 26px;

    align-items: center;
    border: none;
    border-radius: calc(var(--height) / 2);
    cursor: pointer;
    display: flex;
    gap: var(--gap);
    height: var(--height);
    justify-content: center;
    padding-inline: var(--padding);
    user-select: none;
    width: fit-content;
}

.button span {
    color: inherit;
}

.blue {
    background: var(--blue);
}

.gray {
    background: var(--box-shadows);
}

.green {
    background: var(--green);
}

.red {
    background: var(--red);
}

.teal {
    background: var(--teal);
}

.white {
    background: var(--white);
    color: var(--black);
}

.yellow {
    background: var(--yellow);
}

.blue,
.green,
.red,
.teal,
.yellow {
    color: #fff;
}

.button .button {
    height: 75%;
}

.disabled {
    pointer-events: none;
    opacity: .4;
}